export const singlePricing = [
  {
    id: `single-user-standard`,
    name: `Standard`,
    priceMonthly: 19,
    priceYearly: 199,
    featureList: [
      `Unlimited listings`,
      `Unlimited property pages`,
      `Unlimited video tours`,
      `Branded and Non-branded property pages`,
      `Branded and Non-branded video tours`,
      `1 show per listing`,
      `Embed a Show in your MLS`,
      `Embed a show in your own site`,
      `Lead generation`,
      `Lead capture: up to 10/mo`,
      `Chat support`,
    ],
  },
  {
    id: `single-user-professional`,
    name: `Professional`,
    priceMonthly: 29,
    priceYearly: 299,
    featureList: [
      `Agent page`,
      `Add your own video`,
      `Printable flyers`,
      `Up to 3 shows per listing`,
      `Embed a Show in any site`,
      `Custom property page URLs`,
      `Video downloads`,
      `Lead capture: up to 100/mo`,
      `Chat/Phone support`,
    ],
    comingSoonList: [],
    previous: `Standard`,
  },
]

export const groupPricing = [
  {
    id: `group-standard`,
    name: `Standard`,
    priceMonthly: 149,
    priceYearly: 1499,
    featureList: [
      `10 agents`,
      `Unlimited listings`,
      `Unlimited listing pages`,
      `Unlimited video tours`,
      `Embed a Show in my MLS`,
      `Lead generation`,
      `Lead capture: up to 10/mo`,
      `MLS Integration *`,
      `Email support`,
    ],
  },
  {
    id: `group-enhanced`,
    name: `Enhanced`,
    priceMonthly: 299,
    priceYearly: 2999,
    featureList: [
      `25 agents`,
      `Branding listing pages`,
      `Branded video tours`,
      `Embed a show in my own site`,
      `Lead capture: up to 25/mo`,
      `Email and phone support`,
    ],
    comingSoonList: [`Use your own video`],
    highlight: true,
    previous: `Standard`,
  },
  {
    id: `group-professional`,
    name: `Professional`,
    priceMonthly: 499,
    priceYearly: 4999,
    featureList: [
      `50 agents`,
      `Video downloads`,
      `Embed a Show in any site`,
      `Lead capture: up to 100/mo`,
    ],
    comingSoonList: [`Agent pages`, `YouTube upload`],
    previous: `Enhanced`,
  },
]
