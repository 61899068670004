import React, { useState } from 'react'
import { Link } from 'gatsby'

import { singlePricing } from '../data/pricing'

import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'
import CardPricing from '../components/cards/pricing/pricing'

function PricingPage() {
  const [activePrice, setActivePrice] = useState(`monthly`)

  return (
    <Layout hideCta={true}>
      <SEO title="Pricing" slug="/pricing" />
      <StructuredData pageType="webPage" slug="pricing" pageTitle="Pricing" />
      <div className="container max-w-5xl">
        <h1 className="text-center text-3xl lg:text-5xl mb-6">Pricing</h1>
        <p className="text-center text-xl lg:text-3xl mb-6 lg:mb-10">
          Start your 7 day free trial
        </p>

        <ul className="flex justify-center mb-10">
          {[`monthly`, `annual`].map((type, index) => (
            <li key={type}>
              <button
                aria-label="toggle monthly prices"
                className={`capitalize shadow py-2 px-8 text-lg border border-purple-700 focus:outline-none ${
                  activePrice === type ? 'bg-purple-700 text-white' : null
                }
                {' '}
                ${index === 0 ? 'rounded-l-lg' : 'rounded-r-lg'}
                `}
                onClick={() => setActivePrice(type)}
              >
                {type}
              </button>
            </li>
          ))}
        </ul>

        <ul className="grid md:grid-cols-2 gap-6 lg:gap-16 mb-10">
          {singlePricing.map(price => (
            <li key={price.id}>
              <CardPricing activePrice={activePrice} {...price} />
            </li>
          ))}
        </ul>

        <div className="rounded-lg p-6 border-4 border-purple-200 mb-20">
          <h2 className="text-2xl lg:text-3xl mb-4">Group Pricing</h2>
          Interested in an account for multiple agents? Please{' '}
          <Link
            className="underline text-purple-800 font-semibold"
            to="/contact"
          >
            contact us
          </Link>{' '}
          and let&apos;s discuss options.
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage
