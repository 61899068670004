import React from 'react'
import PropTypes from 'prop-types'

import PriceLineItem from './price-line-item'
import PriceFlag from './price-flag'

function CardPricing({
  id,
  name,
  priceMonthly,
  priceYearly,
  previous,
  featureList,
  highlight,
  comingSoonList,
  activePrice,
}) {
  return (
    <div
      className={`${
        highlight ? `mt-16 md:mt-0` : ``
      } relative md:h-full transform hover:scale-105 transition duration-200 ease-in-out`}
    >
      {highlight && (
        <div
          className="absolute"
          style={{ bottom: `100%`, left: `50%`, transform: `translateX(-50%)` }}
        >
          <PriceFlag label="Most Popular" />
        </div>
      )}
      <div
        className={`${
          highlight ? `border-purple-500` : `border-purple-200`
        } rounded-lg border-4 md:h-full md:flex flex-col pb-6`}
      >
        <div className="bg-purple-200 p-4 md:p-6">
          <div className="text-purple-600 text-xl mb-2 font-semibold">
            {name}
          </div>
          <div className="font-light leading-none flex mb-4">
            <span className="text-xl lg:text-4xl">$</span>
            <span className="text-6xl lg:text-7xl">
              {activePrice === `annual` ? priceYearly : priceMonthly}
            </span>
            <span className="text-gray-700 text-xl self-end mb-3">
              {activePrice === `monthly` ? `per month` : `per year`}
            </span>
          </div>
          <div className="font-semibold text-sm text-purple-800">
            {activePrice === `annual` ? (
              <>2 free months - you save ${priceMonthly * 2}!</>
            ) : (
              <>Get first 7 days free</>
            )}
          </div>
        </div>
        <div className="py-6 px-4 lg:p-6 pb-0">
          {previous && (
            <p className="mb-4 md:mb-6 text-xl font-semibold">
              Everything in <span className="text-purple-600">{previous}</span>,
              PLUS:
            </p>
          )}
          <ul className="list-inside flex flex-col text-lg md:text-sm lg:text-lg">
            {featureList.map(feature => (
              <li key={feature}>
                <PriceLineItem item={feature} />
              </li>
            ))}
          </ul>
          {comingSoonList && comingSoonList.length > 0 && (
            <div className="pt-10">
              <div className="font-bold text-sm">Coming Soon</div>
              <ul className="list-inside flex flex-col text-lg md:text-sm lg:text-lg">
                {comingSoonList.map(item => (
                  <li key={item}>
                    <PriceLineItem item={item} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="text-center pt-10 mt-auto">
          <a
            className={`${highlight ? `btn-teal` : `btn-teal-outline`} btn`}
            href={`https://app.realestateshows.com/subscribe?plan=${id}-${activePrice}`}
          >
            Start Your Free Trial
          </a>
        </div>
      </div>
    </div>
  )
}

CardPricing.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  priceMonthly: PropTypes.number.isRequired,
  priceYearly: PropTypes.number.isRequired,
  previous: PropTypes.string,
  featureList: PropTypes.array.isRequired,
  highlight: PropTypes.bool,
  comingSoonList: PropTypes.array,
  activePrice: PropTypes.string,
}

export default CardPricing
