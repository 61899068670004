import React from 'react'
import PropTypes from 'prop-types'

import IconGlitter from '../../../svgs/glitter.inline.svg'
import IconGlitterAlt from '../../../svgs/glitter-alt.inline.svg'

function PriceFlag({ label }) {
  return (
    <span className="text-white text-lg md:text-sm lg:text-lg bg-purple-500 flex p-3 rounded-t">
      <span className="w-4 mr-1 inline-block">
        <IconGlitter />
      </span>
      <span className="inline-block whitespace-no-wrap">{label}</span>
      <span className="w-4 ml-1 inline-block relative" style={{ top: `12px` }}>
        <IconGlitterAlt />
      </span>
    </span>
  )
}

PriceFlag.propTypes = {
  label: PropTypes.string.isRequired,
}

export default PriceFlag
