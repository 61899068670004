import React from 'react'
import PropTypes from 'prop-types'

import IconCheck from '../../../svgs/check.inline.svg'

function PriceLineItem({ item }) {
  return (
    <div className="border-b border-gray-200 last:border-b-0 py-2 text-base">
      <span className="inline-block w-4 text-teal-400 mr-2">
        <IconCheck />
      </span>
      {item}
    </div>
  )
}

PriceLineItem.propTypes = {
  item: PropTypes.string.isRequired,
}

export default PriceLineItem
